import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";

const Template = ({ data, pageContext }) => {
  const title = data.markdownRemark.frontmatter.title;
  const date = data.markdownRemark.frontmatter.date;
  const html = data.markdownRemark.html;
  const { next, prev } = pageContext;

  return (
    <Layout bodyClass="no-sidebar">
      <section id="main">
        <div className="container">
          <div id="content">
            <article className="box post">
              <header>
                <h2>{title}</h2>
              </header>
              <div>
                <span className="date">{date}</span>
              </div>
              <br />
              <div
                className="blogpost"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <p>
                {prev && (
                  <Link to={prev.fields.path}>
                    {prev.frontmatter.title}{" "}
                    <span role="img" aria-label="point-left">
                      👈{" "}
                    </span>
                    Previous
                  </Link>
                )}
              </p>
              <p>
                {next && (
                  <Link to={next.fields.path}>
                    Next{" "}
                    <span role="img" aria-label="point-right">
                      👉
                    </span>
                    {next.frontmatter.title}
                  </Link>
                )}
              </p>
            </article>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const postQuery = graphql`
  query($pathSlug: String!) {
    markdownRemark(fields: { path: { eq: $pathSlug } }) {
      html
      fields {
        path
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;

export default Template;
